<template>
  <section class="transfer">
    <div class="logtype">
      <button class="logtype--one switch-btn" @click="form.rechargeType = 'Airtime'">
        Airtime
      </button>
      <button class="logtype--two switch-btn" @click="form.rechargeType = 'Data'">
        Data
      </button>
      <small class="error" v-if="submittedTwo && !$v.form.rechargeType.required"
        >Recharge type is required.</small
      >
    </div>
    <form class="form" @submit.prevent="submitForm" novalidate="true" autocomplete="off">
      <div class="form__group--three">
        <label for="network" class="form__label">
          <input
            readonly
            type="text"
            id="network"
            name="network"
            placeholder="Network"
            class="form__input hide-caret"
            @focus="networkTypes = true"
            v-model.lazy="$v.form.network.$model"
            :class="{
              'is-invalid': submitted && $v.form.network.$error,
            }"
        /></label>
        <small class="error" v-if="submitted && !$v.form.network.required"
          >Network is required</small
        >
        <img src="../../assets/images/down-arrow.svg" alt="dropdown arrow" />

        <div class="networks" v-if="networkTypes">
          <div class="networks__type" @click="handleMtn">
            <img src="../../assets/images/mtn.png" alt="MTN" /><span>MTN</span>
          </div>
          <div class="networks__type" @click="handle9Mobile">
            <img src="../../assets/images/9mobile.png" alt="9Mobile" /><span
              >9Mobile</span
            >
          </div>
          <div class="networks__type" @click="handleAirtel">
            <img src="../../assets/images/airtel.png" alt="Airtel" /><span>Airtel</span>
          </div>
          <div class="networks__type" @click="handleGlo">
            <img src="../../assets/images/glo.png" alt="GLO" /><span>GLO</span>
          </div>
        </div>
      </div>

      <div class="form__group--three" v-if="form.rechargeType === 'Airtime'">
        <label for="amount" class="form__label">
          <input
            type="tel"
            id="amount"
            name="amount"
            placeholder="Amount"
            class="form__input"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            @focus="showAmounts = true"
            v-model.lazy="$v.form.amount.$model"
            :class="{
              'is-invalid': submitted && $v.form.amount.$error,
            }"
        /></label>
        <small class="error" v-if="submitted && !$v.form.amount.required"
          >Amount number is required</small
        >
        <br />
        <small class="error" v-if="submitted && !$v.form.amount.numeric"
          >Needs to be numeric only.</small
        >
      </div>

      <app-amount-suggest
        v-if="showAmounts"
        @selectedAmount="setAmount"
        type="airtimeData"
      ></app-amount-suggest>

      <!-- <div class="form__group--three">
        <label for="dataBundle" class="form__label">
          <input
            readonly
            type="text"
            id="dataBundle"
            name="dataBundle"
            placeholder="Choose data bundle"
            class="form__input hide-caret"
            @focus="showDataBundle = true"
            v-model.lazy="$v.form.dataBundle.$model"
            :class="{
              'is-invalid': submitted && $v.form.dataBundle.$error
            }"
        /></label>
        <small class="error" v-if="submitted && !$v.form.dataBundle.required"
          >Data bundle is required</small
        >
        <img src="../../assets/images/down-arrow.svg" alt="dropdown arrow" />

        <div
          v-if="showDataBundle"
          v-loading="loadingTwo"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          class="networks"
        >
          <div
            v-for="bundle in dataBundles"
            :key="bundle.productId"
            @click="setDataPlan(bundle)"
            class="saved-beneficiaries__beneficiary"
          >
            <p>
              {{ bundle.databundle }} @ NGN{{
                bundle.amount | formatCurrencyTwo
              }}
              valid for
              {{ bundle.validity }}
            </p>
          </div>
        </div>
      </div> -->

      <div
        class="form__group--three"
        v-if="form.rechargeType === 'Data'"
        v-loading="loadingTwo"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <label for="dataBundle" class="form__label">
          <select
            name="dataBundle"
            id="dataBundle"
            class="form__input form__select"
            v-model.lazy="$v.form.dataBundle.$model"
            @change="setDataPlan"
            :class="{
              'is-invalid': submitted && $v.form.dataBundle.$error,
            }"
          >
            <option disabled value="">Choose data bundle</option>
            <option
              v-for="bundle in dataBundles"
              :key="bundle.productId"
              :value="{ id: bundle.productId, amount: bundle.amount }"
            >
              {{ bundle.databundle }} @ NGN{{ bundle.amount | formatCurrencyTwo }}
              valid for
              {{ bundle.validity }}
            </option>
          </select>
        </label>
        <small class="error" v-if="submitted && !$v.form.dataBundle.required"
          >Data bundle is required</small
        >
      </div>

      <div class="form__group--three">
        <label for="sourceAccount" class="form__label">
          <input
            readonly
            type="tel"
            id="sourceAccount"
            name="sourceAccount"
            placeholder="From"
            class="form__input hide-caret"
            @focus="showFromModal = true"
            autocomplete="off"
            v-model.lazy="$v.form.sourceAccount.$model"
            :class="{
              'is-invalid': submitted && $v.form.sourceAccount.$error,
            }"
        /></label>
        <small class="error" v-if="submitted && !$v.form.sourceAccount.required"
          >Source account is required</small
        >
        <img src="../../assets/images/down-arrow.svg" alt="dropdown arrow" />
      </div>

      <div class="form__group--three">
        <label for="phoneNumber">
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            placeholder="Phone Number"
            class="form__input"
            v-model.lazy="$v.form.phoneNumber.$model"
            :class="{ 'is-invalid': submitted && $v.form.phoneNumber.$error }"
        /></label>
        <small class="error" v-if="submitted && !$v.form.phoneNumber.required"
          >Phone number is required</small
        >
        <br />
        <small class="error" v-if="submitted && !$v.form.phoneNumber.numeric"
          >Needs to be numeric only.</small
        >
      </div>

      <button type="submit" class="btn btn__red btn__group">
        <loading v-if="loading" />
        <span>Pay Now</span>
      </button>
    </form>

    <app-source-accounts
      v-if="showFromModal"
      @sourceDetails="setSourceDetails"
      :showFromModal="showFromModal"
      @close="showFromModal = false"
    ></app-source-accounts>

    <app-pin-modal
      ref="otpInput"
      v-if="showPinModal"
      :showPinModal="showPinModal"
      @close="showPinModal = false"
      @userPin="setUserPin"
    ></app-pin-modal>

    <app-otp-modal
      ref="otpInput"
      v-if="showOtpModal"
      :showOtpModal="showOtpModal"
      @close="showOtpModal = false"
      @otPin="setOtpValue"
    ></app-otp-modal>
  </section>
</template>

<script>
import api from "@/api/api.js";
import { mapState } from "vuex";
import { required, numeric } from "vuelidate/lib/validators";
import SourceAccounts from "@/components/Transfers/Widgets/SourceAccounts.vue";
import AmountSelector from "@/components/Transfers/Widgets/AmountSelector.vue";
import PinModal from "@/widgets/PinModal.vue";
import OtpModal from "@/widgets/OtpModal.vue";
export default {
  components: {
    "app-source-accounts": SourceAccounts,
    "app-amount-suggest": AmountSelector,
    "app-pin-modal": PinModal,
    "app-otp-modal": OtpModal,
  },
  computed: mapState(["userAccounts", "user", "loading"]),
  data() {
    return {
      loading: false,
      loadingTwo: false,
      submitted: false,
      submittedTwo: false,
      showFromModal: false,
      networkTypes: false,
      showAmounts: false,
      passwordFieldType: "password",
      selectedAccount: "",
      dataBundles: null,
      showPinModal: false,
      showOtpModal: false,
      // showDataBundle: false,
      form: {
        rechargeType: "",
        sourceAccount: "",
        network: "",
        phoneNumber: "",
        amount: "",
        dataBundle: "",
        pin: "",
        debitNuban: "",
        accountType: "",
        isWallet: false,
        dataAmount: "",
        productId: "",
      },
    };
  },
  validations: {
    form: {
      rechargeType: { required },
      sourceAccount: { required },
      network: { required },
      phoneNumber: { required, numeric },
      amount: { required, numeric },
      dataBundle: { required },
    },
  },
  watch: {
    selectedAccount: function (newValue) {
      if (newValue !== "") {
        this.form.sourceAccount = `${newValue.NUBAN} | ${newValue.ACCT_TYPE}`;
      }
    },
    "form.accountType": function (newValue) {
      if (newValue === "Wallet") {
        this.form.isWallet = true;
      }
    },
    "form.network": function (newValue) {
      if (newValue !== "" && this.form.rechargeType === "Data") {
        this.getDataBundle(newValue);
      }
    },
    showPinModal: function (newValue) {
      if (newValue === true) {
        this.$nextTick(() => {
          this.fixChromeMask();
        });
      }
    },
  },
  methods: {
    setAmount(value) {
      this.form.amount = value;
      this.showAmounts = false;
    },
    setSourceDetails(account) {
      this.form.debitNuban = account.NUBAN;
      this.form.accountType = account.ACCT_TYPE;
      this.selectedAccount = account;
      this.showFromModal = false;
    },
    handleMtn() {
      this.form.network = "MTN";
      this.networkTypes = false;
    },
    handle9Mobile() {
      this.form.network = "9Mobile";
      this.networkTypes = false;
    },
    handleAirtel() {
      this.form.network = "Airtel";
      this.networkTypes = false;
    },
    handleGlo() {
      this.form.network = "GLO";
      this.networkTypes = false;
    },
    getDataBundle(value) {
      let selectedNetwork;

      if (value === "9Mobile") {
        selectedNetwork = "9mob";
      } else {
        selectedNetwork = value.toLowerCase();
      }

      let payload = {
        network: selectedNetwork,
      };

      this.loadingTwo = true;

      api
        .getDataPlans(payload)
        .then((response) => {
          this.loadingTwo = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            let dataPlans = response.Data;
            this.dataBundles = dataPlans;
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loadingTwo = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
    setDataPlan() {
      this.form.dataAmount = this.form.dataBundle.amount;
      this.form.productId = this.form.dataBundle.id;
    },
    fixChromeMask() {
      // Modal mask fix for chrome
      this.$refs.otpInput.$el.children[0].setAttribute("autocomplete", "new-password");
      const inputs = this.$refs.otpInput.$el.querySelectorAll(".otp-input");
      inputs.forEach((i) => i.setAttribute("autocomplete", "new-password"));
    },
    performAirtimeRecharge() {
      let payload = {
        airtimeAmount: String(this.form.amount),
        phoneNumber: this.form.phoneNumber,
        network: this.form.network,
        IsFromWallet: this.form.isWallet,
        nuban: this.form.debitNuban,
        SMID: this.user.profileInfo.SMID,
        TransactionPin: this.form.pin,
      };
      this.loading = true;
      this.$store.commit("toggleLoading", true);

      api
        .handleAirtimeRecharge(payload)
        .then((response) => {
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Success",
              {
                confirmButtonText: "OK",
                type: "success",
                dangerouslyUseHTMLString: true,
              }
            );
            this.$store.dispatch("handleGetUserAccounts");
          } else {
            this.loading = false;
            this.$store.commit("toggleLoading", false);
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Error",
              {
                confirmButtonText: "OK",
                type: "error",
                dangerouslyUseHTMLString: true,
              }
            );
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$store.commit("toggleLoading", false);
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
    performDataRecharge() {
      let payload = {
        DataAmount: String(this.form.dataBundle.amount),
        productid: this.form.dataBundle.id,
        nuban: this.form.debitNuban,
        phoneNumber: this.form.phoneNumber,
        network: this.form.network,
        IsFromWallet: this.form.isWallet,
        SMID: this.user.profileInfo.SMID,
        TransactionPin: this.form.pin,
      };

      this.$store.commit("toggleLoading", true);

      api
        .handleDataRecharge(payload)
        .then((response) => {
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Success",
              {
                confirmButtonText: "OK",
                type: "success",
                dangerouslyUseHTMLString: true,
              }
            );
            this.$store.dispatch("handleGetUserAccounts");
          } else {
            this.$store.commit("toggleLoading", false);
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Error",
              {
                confirmButtonText: "OK",
                type: "error",
                dangerouslyUseHTMLString: true,
              }
            );
          }
        })
        .catch((error) => {
          this.$store.commit("toggleLoading", false);
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
    setUserPin(value) {
      this.form.pin = value;
      this.showPinModal = false;
      let payload = {
        userId: this.user.profileInfo.SMID,
        pin: value,
      };

      api
        .handlePinValidation(payload)
        .then((response) => {
          // console.log(response, "PIN validation");
          let responseStatus = response.Status;
          let responseMessage = response.Message;
          if (responseStatus && responseMessage === "Pin Validated Successfully") {
            this.$message({
              showClose: true,
              message: `PIN VALIDATED SUCCESSFULLY`,
              type: "success",
              duration: 10000,
            });
            this.showOtpModal = true;

            if (this.user.profileInfo.iswalletonly) {
              api
                .generateWalletOtp({ phone: this.user.walletInfo.mobile })
                .then((response) => {
                  let responseStatus = response.Status;
                  if (responseStatus) {
                    this.$message({
                      showClose: true,
                      message: `ENTER THE OTP SENT TO YOUR MOBILE NUMBER`,
                      type: "success",
                      duration: 10000,
                    });
                  }
                })
                .catch((error) => {
                  this.$message({
                    showClose: true,
                    message: `${error}`,
                    type: "error",
                    duration: 10000,
                  });
                });
            } else {
              api
                .generateTransactionOtp({
                  nuban: this.user.profileInfo.PryAccount,
                })
                .then((response) => {
                  let responseStatus = response.Status;
                  if (responseStatus) {
                    this.$message({
                      showClose: true,
                      message: `ENTER THE OTP SENT TO YOUR MOBILE NUMBER`,
                      type: "success",
                      duration: 10000,
                    });
                  }
                })
                .catch((error) => {
                  this.$message({
                    showClose: true,
                    message: `${error}`,
                    type: "error",
                    duration: 10000,
                  });
                });
            }
          } else {
            this.$message({
              showClose: true,
              message: `Error validating pin`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
      // if (this.form.rechargeType === "Airtime") {
      //   this.performAirtimeRecharge();
      // } else if (this.form.rechargeType === "Data") {
      //   this.performDataRecharge();
      // }
    },

    setOtpValue(value) {
      this.showOtpModal = false;
      this.otp = value;

      if (this.user.profileInfo.iswalletonly) {
        api
          .verifyWalletOtp({
            phone: this.user.walletInfo.mobile,
            otp: value,
          })
          .then((response) => {
            let responseStatus = response.Status;
            if (responseStatus) {
              if (this.form.rechargeType === "Airtime") {
                this.performAirtimeRecharge();
              } else if (this.form.rechargeType === "Data") {
                this.performDataRecharge();
              }
            }
          })
          .catch((error) => {
            this.$message({
              showClose: true,
              message: `${error}`,
              type: "error",
              duration: 10000,
            });
          });
      } else {
        api
          .verifyTransactionOtp({
            nuban: this.user.profileInfo.PryAccount,
            otp: value,
          })
          .then((response) => {
            // console.log(response);
            let responseStatus = response.Status;
            if (responseStatus) {
              if (this.form.rechargeType === "Airtime") {
                this.performAirtimeRecharge();
              } else if (this.form.rechargeType === "Data") {
                this.performDataRecharge();
              }
            } else {
              this.$message({
                showClose: true,
                message: `OTP could not be validated`,
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((error) => {
            this.$message({
              showClose: true,
              message: `${error}`,
              type: "error",
              duration: 10000,
            });
          });
      }
    },
    submitForm() {
      this.submittedTwo = true;

      this.$v.form.$touch();

      if (this.$v.form.rechargeType.$invalid) {
        return;
      }

      if (this.form.rechargeType === "Airtime") {
        this.submitted = true;

        this.$v.form.$touch();

        if (
          this.$v.form.network.$invalid &&
          this.$v.form.amount.$invalid &&
          this.$v.form.sourceAccount.$invalid &&
          this.$v.form.phoneNumber.$invalid &&
          this.$v.form.pin.$invalid
        ) {
          return;
        }

        this.showPinModal = true;
      } else if (this.form.rechargeType === "Data") {
        this.submitted = true;

        this.$v.form.$touch();

        if (
          this.$v.form.network.$invalid &&
          this.$v.form.dataBundle.$invalid &&
          this.$v.form.sourceAccount.$invalid &&
          this.$v.form.phoneNumber.$invalid &&
          this.$v.form.pin.$invalid
        ) {
          return;
        }

        this.showPinModal = true;
      }
    },
  },
  mounted() {
    const btns = document.querySelectorAll(".switch-btn");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        let current = document.getElementsByClassName("active-login");

        if (current.length > 0) {
          current[0].className = current[0].className.replace(" active-login", "");
        }
        this.className += " active-login";
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
